export const ESCORT_POSITIONS = ['lead', 'chase', 'high-pole', 'steer', 'route-survey', 'third-car', 'fourth-car'];

export const CERT_TYPES = [
  { name: 'Amber Light', value: 'amber-light' },
  { name: 'CDL', value: 'cdl' },
  { name: 'Florida Safety CDL', value: 'florida-safety-cdl' },
  { name: 'New Mexico Cert', value: 'new-mexico-cert' },
  { name: 'New York Cert', value: 'new-york-cert' },
  { name: 'Pennsylvania Insurance', value: 'Pennsylvania Insurance' },
  { name: 'Pilot Escort / Flagger', value: 'pilot-escort-flagger' },
  { name: 'TWIC', value: 'twic' },
];

export const LOAD_STATUSES = ['open', 'covered'];

export const US_STATES = [
  'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DC', 'DE', 'FL', 'GA',
  'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD',
  'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ',
  'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC',
  'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY',
].sort();

export const CANADIAN_POSTAL_CODES_TO_NAMES = {
  AB: 'Alberta',
  BC: 'British Columbia',
  MB: 'Manitoba',
  NB: 'New Brunswick',
  NL: 'Newfoundland and Labrador',
  NS: 'Nova Scotia',
  NT: 'Northwest Territories',
  NU: 'Nunavut',
  ON: 'Ontario',
  PE: 'Prince Edward Island',
  QC: 'Quebec',
  SK: 'Saskatchewan',
  YT: 'Yukon',
};

export const CANADIAN_NAMES_TO_POSTAL_CODES = Object.fromEntries(
  Object.entries(CANADIAN_POSTAL_CODES_TO_NAMES).map(([k, v]) => [v, k]),
);// swap keys and values

export const STATE_NAMES_TO_CODE = {
  Alabama: 'AL',
  Alaska: 'AK',
  Arizona: 'AZ',
  Arkansas: 'AR',
  California: 'CA',
  Colorado: 'CO',
  Connecticut: 'CT',
  Delaware: 'DE',
  Florida: 'FL',
  Georgia: 'GA',
  Hawaii: 'HI',
  Idaho: 'ID',
  Illinois: 'IL',
  Indiana: 'IN',
  Iowa: 'IA',
  Kansas: 'KS',
  Kentucky: 'KY',
  Louisiana: 'LA',
  Maine: 'ME',
  Maryland: 'MD',
  Massachusetts: 'MA',
  Michigan: 'MI',
  Minnesota: 'MN',
  Mississippi: 'MS',
  Missouri: 'MO',
  Montana: 'MT',
  Nebraska: 'NE',
  Nevada: 'NV',
  'New Hampshire': 'NH',
  'New Jersey': 'NJ',
  'New Mexico': 'NM',
  'New York': 'NY',
  'North Carolina': 'NC',
  'North Dakota': 'ND',
  Ohio: 'OH',
  Oklahoma: 'OK',
  Oregon: 'OR',
  Pennsylvania: 'PA',
  'Rhode Island': 'RI',
  'South Carolina': 'SC',
  'South Dakota': 'SD',
  Tennessee: 'TN',
  Texas: 'TX',
  Utah: 'UT',
  Vermont: 'VT',
  Virginia: 'VA',
  Washington: 'WA',
  'West Virginia': 'WV',
  Wisconsin: 'WI',
  Wyoming: 'WY',
  'District of Columbia': 'DC',
  ...CANADIAN_NAMES_TO_POSTAL_CODES,
};

export const US_STATES_BY_REGION = {
  Midwest: [
    'IL',
    'IN',
    'IA',
    'KS',
    'ME',
    'MI',
    'MN',
    'MO',
    'NE',
    'ND',
    'OH',
    'SD',
    'WI',
  ],
  Northeast: [
    'CT',
    'MA',
    'NH',
    'NJ',
    'NY',
    'PA',
    'RI',
  ],
  South: [
    'AL',
    'AR',
    'DC',
    'DE',
    'FL',
    'GA',
    'KY',
    'LA',
    'MD',
    'MS',
    'NC',
    'OK',
    'SC',
    'TN',
    'TX',
    'VA',
    'WV',
  ],
  West: [
    'AK',
    'AZ',
    'CA',
    'CO',
    'HI',
    'ID',
    'MT',
    'NV',
    'NM',
    'OR',
    'UT',
    'VT',
    'WA',
    'WY',
  ],
};

export const ALL_REGIONS = { ...US_STATES_BY_REGION, ...CANADIAN_NAMES_TO_POSTAL_CODES };
export const ALL_STATES = [ ...US_STATES, ...Object.keys(CANADIAN_POSTAL_CODES_TO_NAMES) ];

export const PROTECTED_ROUTE_PATTERNS = [
  /^\/dashboard/,
];

